import { GITHUB_URL, TWITTER_URL } from "../constants";
import GitHubIconLink from "./PillLinks/gitHubButtons/GitHubIconLink";

export default function Socials() {
  return (
    <div className="flex space-x-6 md:order-2">
      <a
        href={TWITTER_URL}
        target="_blank"
        rel="noreferrer"
        className="text-gray-400 hover:text-primary"
      >
        <span className="sr-only">Twitter</span>
        <svg
          className="h-6 w-6"
          fill="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      </a>
      <GitHubIconLink href={GITHUB_URL} />
      {/* <a
                href={EMAIL_URL}
                target='_blank'
                rel="noreferrer"
                className="text-gray-400 hover:text-primary"
            >
                <span className="sr-only">Email 0xNeon@protonmail.com</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
            </a> */}
    </div>
  );
}

